<template>
  <div>
    <p ref="pHidden" hidden>Monto:{{ monto }}</p>
    <v-text-field v-model="monto" ref="inputRef" :class="classDynamic" :outlined="bOutlined" :dense="bDense"
      :disabled="bDisabled" :label="labelGlobal" placeholder="$0.00" :min="0" :max="9999999" maxlength="16" suffix="MXN"
      prefix="$" hide-details="" @keyup="changeMontoGlobal">
    </v-text-field>
  </div>
</template>
<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    flagModal: Boolean,
    emptyValue: { type: Boolean, default: false },
    modelValue: Number, // Vue 2: value
    options: Object,
    classDynamic: String,
    bOutlined: { type: Boolean, default: false },
    bDense: { type: Boolean, default: false },
    bDisabled: { type: Boolean, default: false },
    bRefreshValue: { type: Boolean, default: false },
    labelGlobal: { type: String, default: "Monto" },
  },
  data() {
    return {
      monto: 0,
    };
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({
      locale: "en-US",
      currency: "USD",
      currencyDisplay: "hidden",
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    });

    return { inputRef };
  },
  beforeMount() {
    if (this.flagModal) {
      this.getValue();
    }
  },
  methods: {
    getValue() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.monto = this.modelValue;
          this.bLoading = true;
        });
      }, 500);
    },
    changeMontoGlobal() {

      this.monto = this.monto.replace("-", "");
      this.$emit("changeMontoGlobal", this.monto);
    },
  },
  watch: {
    flagModal() {
      if (this.flagModal) {
        this.monto = 0;
        this.getValue();
      }
    },
    emptyValue() {
      this.monto = 0;
      this.$emit("changeMontoGlobal", 0);
    },
    bRefreshValue() {
      this.getValue();
    },
  },
};
</script>
<style>
.input-categoria>>>.v-input__slot {
  /* background-color: #ce0000; */
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px;
  font-size: 14px;
}

.input-categoria>>>input:-webkit-autofill,
.input-categoria>>>input:-webkit-autofill:hover,
.input-categoria>>>input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 30px #f9f9f9 inset !important;
  -webkit-box-shadow: 0 0 0px 30px #f9f9f9 inset !important;
}

.input-categoria>>>.theme--light.v-label {
  color: #999898;
  border-color: #e5e5e5 !important;
}

/* inputs global style */
.input-categoria .v-text-field__details {
  display: none;
}

.input-categoria .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #85858529 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.input-categoria fieldset {
  border: 1px solid #e5e5e5 !important;
}

.input-categoria input {
  color: #928d8d !important;
  letter-spacing: 0px !important;
}
</style>